import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
// ----------------------------------------------------------------------
export default function MainLayout() {
    const { pathname } = useLocation();
    const isHome = pathname === '/';
    return (_jsxs(Box, { sx: { display: 'flex', flexDirection: 'column', height: 1 }, children: [_jsx(Header, {}), _jsx(Box, { component: "main", sx: {
                    flexGrow: 1,
                    ...(!isHome && {
                        pt: { xs: 8, md: 11 },
                    }),
                }, children: _jsx(Outlet, {}) })] }));
}
