import { jsx as _jsx } from "react/jsx-runtime";
// routes
import Iconify from '../../../components/iconify';
import { PATH_AUTH } from '../../../routes/paths';
// ----------------------------------------------------------------------
const navConfig = [
    {
        title: 'Home',
        icon: _jsx(Iconify, { icon: "eva:home-fill" }),
        path: '/',
    },
    {
        title: 'Categories',
        icon: _jsx(Iconify, { icon: "carbon:categories" }),
        path: 'categories',
    },
    {
        title: 'About us',
        icon: _jsx(Iconify, { icon: "mdi:about" }),
        path: 'about',
    },
    {
        title: 'Location',
        path: 'location',
        icon: _jsx(Iconify, { icon: "zondicons:location" }),
    },
    {
        title: 'Testimonials',
        path: 'testimonials',
        icon: _jsx(Iconify, { icon: "carbon:review" }),
    },
    {
        title: 'Login',
        icon: _jsx(Iconify, { icon: "material-symbols:login-sharp" }),
        path: PATH_AUTH.login,
    },
];
export default navConfig;
